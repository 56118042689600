import React, { Fragment } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import './FAQ.css';

export const FAQ = () => (
    <Fragment>
        <h2>BMW Labs Registration - FAQ</h2>
        <PanelGroup accordion={true} id="faq-accordion">
            <Panel eventKey="1">
                <Panel.Heading>
                    <Panel.Title toggle={true}>Question 1</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible={true}>Answer 1</Panel.Body>
            </Panel>
            <Panel eventKey="2">
                <Panel.Heading>
                    <Panel.Title toggle={true}>Question 2</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible={true}>Answer 2</Panel.Body>
            </Panel>
        </PanelGroup>
    </Fragment>
);
