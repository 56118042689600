import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { AWS } from '@aws-amplify/core';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { Alert, FormControl, FormGroup } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { ILoginRequest } from '../../../shared/interfaces/ILoginRequest';
import { ILoginResponse } from '../../../shared/interfaces/ILoginResponse';
import { LoaderButton } from '../components/LoaderButton';
import { config } from '../config';
import { UserStore } from '../UserStore';
import './Login.css';

interface ILoginProps extends RouteComponentProps<{}> {
    user: UserStore;
}

interface ILoginState {
    error: boolean;
    isLoading: boolean;
    username: string;
    password: string;
}

@inject(UserStore.INJECT)
@observer
export class Login extends Component<ILoginProps, ILoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: false,
            isLoading: false,
            username: '',
            password: ''
        };
    }

    public async componentDidMount() {
        if (config.stage === 'dev') {
            this.props.user.isAuthenticated = true;
            return;
        }
    }

    public render() {
        return (
            <div className="Login">
                {this.getError()}
                <form onSubmit={this.handleSubmit}>
                    <h3>Login using your BMW Connected Drive Account</h3>

                    <FormGroup controlId="username" bsSize="large">
                        <FormControl
                            autoFocus={true}
                            value={this.state.username}
                            onChange={this.setUsername}
                            placeholder="Email or username"
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <FormControl
                            value={this.state.password}
                            onChange={this.setPassword}
                            type="password"
                            placeholder="Password"
                        />
                    </FormGroup>
                    <LoaderButton
                        block={true}
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                    />
                    <p className="footer">
                        By signing in with your BMW ConnectedDrive account you
                        agree with the{' '}
                        <a
                            className="link"
                            href="https://labs.bmw.com/terms.html"
                        >
                            Terms&amp;Conditions
                        </a>{' '}
                        of BMW Labs.
                    </p>
                </form>
            </div>
        );
    }

    private getError() {
        if (!this.state.error) {
            return null;
        }
        return (
            <Fragment>
                <Alert bsStyle="danger">
                    <h4>Login error</h4>
                    <p>An error occurred during login.</p>
                </Alert>
            </Fragment>
        );
    }
    private validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    private setUsername = (e: any) => {
        this.setState({
            username: e.target.value
        });
    };

    private setPassword = (e: any) => {
        this.setState({
            password: e.target.value
        });
    };

    private handleSubmit = async (event: any) => {
        event.preventDefault();

        this.setState({ isLoading: true });

        const loginRequest: ILoginRequest = {
            username: this.state.username,
            password: this.state.password
        };
        try {
            const loginResponse: ILoginResponse = await API.post(
                'account',
                `/login`,
                { body: loginRequest }
            );
            const federatedResponse = {
                expires_at: loginResponse.expires_at,
                identity_id: loginResponse.identity_id,
                token: loginResponse.token
            };
            const federatedUser = {
                mail: loginResponse.mail,
                name: loginResponse.mail
            };
            const credentials = await Auth.federatedSignIn(
                'developer',
                federatedResponse,
                federatedUser
            );

            AWS.config.credentials = credentials;
            this.setState({ error: false, isLoading: false });
            this.props.user.isAuthenticated = true;
            this.props.history.push('/');
        } catch (e) {
            this.setState({ error: true, isLoading: false });
        }
    };
}
