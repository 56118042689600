import { inject } from 'mobx-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserStore } from '../UserStore';

@inject(UserStore.INJECT)
export class UnauthenticatedRoute extends React.Component<any, any> {
    public render() {
        if (!this.props.user.isAuthenticated) {
            return <Route component={this.props.component} />;
        } else {
            return <Redirect to="/" />;
        }
    }
}
