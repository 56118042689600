import React from 'react';
import { Alert, Button } from 'react-bootstrap';

interface IAlertDismissableProps {
    onClick: () => void | Promise<void>;
    buttonShowText: string;
    buttonPerformActionText: string;
}

export class AlertDismissable extends React.Component<
    IAlertDismissableProps,
    { show: boolean }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            show: false
        };
    }

    public dismiss = () => {
        this.setState({ show: false });
    };

    public show = () => {
        this.setState({ show: true });
    };

    public render() {
        if (this.state.show) {
            return (
                <Alert bsStyle="danger" onDismiss={this.dismiss}>
                    {this.props.children}
                    <p>
                        <Button bsStyle="danger" onClick={this.props.onClick}>
                            {this.props.buttonPerformActionText}
                        </Button>
                        <span> or </span>
                        <Button onClick={this.dismiss}>Close</Button>
                    </p>
                </Alert>
            );
        }

        return <Button onClick={this.show}>{this.props.buttonShowText}</Button>;
    }
}
