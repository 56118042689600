import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { Alert, Button, Glyphicon, ListGroup, Modal } from 'react-bootstrap';
import {
    IServiceActivation,
    IServiceActivationCredentials,
    IServiceActivationOauth,
    IServiceActivationSignup
} from '../../../shared/interfaces/IServiceActivation';
import { AlertDismissable } from '../components/AlertDismissable';
import { ListGroupItemWithImage } from '../components/ListGroupItemWithImage';
import { config } from '../config';
import { UserStore } from '../UserStore';
import { CredentialsRegistration } from './CredentialsRegistration';
import './spinning.css';

interface IServiceListProps {
    user?: UserStore;
}

interface IServiceListState {
    showModal: { [provider: string]: boolean };
}

@inject(UserStore.INJECT)
@observer
export class ServiceList extends React.Component<IServiceListProps, IServiceListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: {}
        };
    }

    public async componentDidMount() {
        return this.props.user!.refreshServices();
    }

    public render() {
        const services = this.props.user!.services;
        if (!services) {
            return <Glyphicon glyph="refresh" className="spinning" />;
        }
        const activeServiceCount = services.filter(s => s.active).length;
        const getBrandLogo = (service: IServiceActivation) => {
            // remove suffix (e.g. "_test")
            const provider = service.provider
                .split('_')
                .slice(0, 1)
            const imagePath = `${config.assetHost}/brands/${provider}.png`;
            return imagePath;
        };
        const activeService = (service: IServiceActivation) => {
            const childProps = {
                header: service.name,
                key: service.provider,
                bsStyle: service.active ? 'success' : 'warning'
            };
            return (
                <ListGroupItemWithImage props={childProps} image={getBrandLogo(service)}>
                    <Fragment>
                        <p>Service is active.</p>
                        <AlertDismissable
                            buttonShowText="deactivate"
                            buttonPerformActionText={`deactivate ${service.name}`}
                            onClick={this.props.user!.deactivateService(service.provider)}
                        >
                            <h4>Deactivate {service.name}</h4>
                            <p>
                                This will remove the link between your BMW Connected drive account and your{' '}
                                {service.name} account. You won't be able to use this account in your vehicle unless you
                                activate it again.
                            </p>
                        </AlertDismissable>
                    </Fragment>
                    {this.getModal(service)}
                </ListGroupItemWithImage>
            );
        };
        const inactiveService = (service: IServiceActivation) => {
            const childProps = {
                header: service.name,
                key: service.provider
            };
            return (
                <ListGroupItemWithImage props={childProps} image={getBrandLogo(service)}>
                    <Fragment>
                        <p>Service is not active.</p>
                        <Button bsStyle="primary" onClick={this.handleShowModal(service.provider)}>
                            click to activate
                        </Button>
                    </Fragment>
                    {this.getModal(service)}
                </ListGroupItemWithImage>
            );
        };

        const res = services.map(service => (service.active ? activeService(service) : inactiveService(service)));
        const hint = (
            <Alert bsStyle="info">
                <h4>Please select your preferred services:</h4>
                <p>
                    By selecting your preferred services, you can connect your service accounts with your BMW Connected
                    Drive account. You will then be able to access these services from within your car.
                </p>
            </Alert>
        );
        return (
            <Fragment>
                {activeServiceCount === 0 ? hint : ''}
                <div className="services">
                    <h3>Your Services</h3>
                    <ListGroup>{res}</ListGroup>
                </div>
            </Fragment>
        );
    }

    private getModal(service: IServiceActivation) {
        switch (service.type) {
            case 'OAUTH':
                return this.getOauthModal(service);
            case 'CREDENTIALS':
                return this.getCredentialsModal(service);
            case 'SIGNUP':
                return this.getSignupModal(service);
        }
    }

    private getCredentialsModal(service: IServiceActivationCredentials) {
        return (
            <Modal
                backdrop={false}
                show={this.state.showModal[service.provider]}
                onHide={this.handleCloseModal(service.provider)}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>How-to activate {service.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CredentialsRegistration service={service} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseModal(service.provider)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private getOauthModal(service: IServiceActivationOauth) {
        return (
            <Modal
                backdrop={false}
                show={this.state.showModal[service.provider]}
                onHide={this.handleCloseModal(service.provider)}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>How-to activate {service.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        In order to activate <strong>{service.name}</strong> you will be forwarded to the brand's
                        website. After entering your credentials there, you will be redirected to this page.
                    </p>
                    <p>
                        <Button href={service.authorizeUrl} bsStyle="info">
                            Activate {service.name}
                        </Button>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseModal(service.provider)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private getSignupModal(service: IServiceActivationSignup) {
        return (
            <Modal
                backdrop={false}
                show={this.state.showModal[service.provider]}
                onHide={this.handleCloseModal(service.provider)}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>How-to activate {service.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        In order to activate <strong>{service.name}</strong> you will be forwarded to the brand's
                        website. After signing up with your payment data there, you will be redirected to this page.
                    </p>
                    <p>
                        <Button href={service.signupUrl} bsStyle="info">
                            Activate {service.name}
                        </Button>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseModal(service.provider)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private handleCloseModal = (provider: string) => () => {
        this.setState({
            showModal: { [provider]: false }
        });
        return this.props.user!.refreshServices();
    };

    private handleShowModal = (provider: string) => () => {
        this.setState({ showModal: { [provider]: true } });
    };
}
