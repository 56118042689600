import Auth from '@aws-amplify/auth';
import { AWS } from '@aws-amplify/core';
import { inject } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { UserStore } from './UserStore';

interface IAppState {
    isAuthenticating: boolean;
}

interface IAppProps extends RouteComponentProps<any> {
    user?: UserStore;
}
@inject('user')
class App extends Component<IAppProps, IAppState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isAuthenticating: true
        };
    }

    public async componentDidMount() {
        try {
            const credentials = await Auth.currentCredentials();
            if (credentials.authenticated) {
                this.props.user!.isAuthenticated = true;
                AWS.config.credentials = credentials;
            }
        } catch (e) {
            console.debug(e);
        }

        this.setState({ isAuthenticating: false });
    }

    public render() {
        return (
            !this.state.isAuthenticating && (
                <Fragment>
                    <nav
                        className="navbar navbar-default navbar-fixed-top topnav"
                        role="navigation"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                    >
                        <div id="header-top-margin" />
                        <div className="container topnav">
                            <div className="navbar-header">
                                <Link to="/">
                                    <img
                                        id="main-logo"
                                        src="/img/BMWLabs_logo_with_BMW.png"
                                        alt=""
                                    />
                                </Link>
                                <Navbar.Toggle />
                            </div>
                            <Navbar.Collapse id="navbarCollapse">
                                <Nav
                                    pullRight={false}
                                    className="nav navbar-nav"
                                >
                                    {this.props.user!.isAuthenticated ? (
                                        <Fragment>
                                            <NavItem>
                                                <NavLink to="/faq">FAQ</NavLink>
                                            </NavItem>
                                            <NavItem onClick={this.handleLogout}>
                                                Logout
                                            </NavItem>
                                        </Fragment>
                                    ) : (
                                        <NavItem>
                                            <NavLink to="/login">Login</NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </nav>

                    <div className="faq container">
                        <Routes />
                    </div>
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h6 className="pull-left">
                                        © BMW AG {new Date().getFullYear()}
                                    </h6>
                                    <ul className="list-inline pull-right">
                                        <li>
                                            <a
                                                className="link"
                                                href="https://labs.bmw.com/dataPrivacy.html"
                                            >
                                                Data Privacy
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="link"
                                                href="https://labs.bmw.com/terms.html"
                                            >
                                                Legal Notice
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Fragment>
            )
        );
    }

    private handleLogout = async () => {
        await Auth.signOut();
        this.props.user!.isAuthenticated = false;
        this.props.history.push('/');
    };
}

export const Application = withRouter(App);
