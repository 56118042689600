import API from '@aws-amplify/api';
import React, { Component, Fragment } from 'react';
import { Alert, Button, FormControl, FormGroup } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { ISsiLoginRequest } from '../../../shared/interfaces/ssi/ISsiLoginRequest';
import { ISsiLoginResponse } from '../../../shared/interfaces/ssi/ISsiLoginResponse';
import { LoaderButton } from '../components/LoaderButton';
import { IssueCredential } from '../components/ssi/IssueCredential';
import './Ssi.css';
import QRCode from 'react-qr-code';

interface ILoginProps extends RouteComponentProps<{}> {}

interface ILoginState {
    error: boolean;
    isLoading: boolean;
    username: string;
    password: string;
    loginResponse: ISsiLoginResponse | null;
    advancedOpen: boolean;
}

export class Ssi extends Component<ILoginProps, ILoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: false,
            isLoading: false,
            username: '',
            password: '',
            loginResponse: null,
            advancedOpen: false
        };
    }

    public render() {
        if (this.state.loginResponse === null) {
            return (
                <div className="Login">
                    {this.getError()}
                    <form onSubmit={this.handleSubmit}>
                        <h3>Login using your BMW Connected Drive INT Account</h3>

                        <FormGroup controlId="username" bsSize="large">
                            <FormControl
                                autoFocus={true}
                                value={this.state.username}
                                onChange={this.setUsername}
                                placeholder="Email or username"
                            />
                        </FormGroup>
                        <FormGroup controlId="password" bsSize="large">
                            <FormControl
                                value={this.state.password}
                                onChange={this.setPassword}
                                type="password"
                                placeholder="Password"
                            />
                        </FormGroup>
                        <LoaderButton
                            block={true}
                            bsSize="large"
                            disabled={!this.validateForm()}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Login"
                            loadingText="Logging in…"
                        />
                        <p className="footer">
                            By signing in with your BMW ConnectedDrive account you agree with the{' '}
                            <a className="link" href="https://labs.bmw.com/terms.html">
                                Terms&amp;Conditions
                            </a>{' '}
                            of BMW Labs.
                        </p>
                    </form>
                </div>
            );
        } else {
            return (
                <>
                    <div>
                        <h3>Scan this QR Code with your smartphone to connect your Wallet with you BMW Account:</h3>
                        <QRCode value={this.state.loginResponse.invitation_url} />
                    </div>
                    <Button onClick={() => this.setState({ advancedOpen: !this.state.advancedOpen })}>advanced</Button>
                    <div style={{ display: this.state.advancedOpen ? 'block' : 'none' }}>
                        <IssueCredential loginResponse={this.state.loginResponse} />
                    </div>
                </>
            );
        }
    }

    private getError() {
        if (!this.state.error) {
            return null;
        }
        return (
            <Fragment>
                <Alert bsStyle="danger">
                    <h4>Login error</h4>
                    <p>An error occurred during login.</p>
                </Alert>
            </Fragment>
        );
    }
    private validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    private setUsername = (e: any) => {
        this.setState({
            username: e.target.value
        });
    };

    private setPassword = (e: any) => {
        this.setState({
            password: e.target.value
        });
    };

    private handleSubmit = async (event: any) => {
        event.preventDefault();

        this.setState({ isLoading: true });

        const loginRequest: ISsiLoginRequest = {
            username: this.state.username,
            password: this.state.password
        };
        try {
            const loginResponse: ISsiLoginResponse = await API.post('ssi', `/login`, { body: loginRequest });
            this.setState({ error: false, isLoading: false, loginResponse });
        } catch (e) {
            this.setState({ error: true, isLoading: false });
        }
    };
}
