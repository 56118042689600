import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserStore } from '../UserStore';

@inject(UserStore.INJECT)
@observer
export class AuthenticatedRoute extends React.Component<any, any> {
    public render() {
        if (this.props.user.isAuthenticated) {
            return (
                <Route
                    component={this.props.component}
                    path={this.props.path}
                />
            );
        } else {
            return <Redirect to="/" />;
        }
    }
}
