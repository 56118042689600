export const config = {
    apiGateway: {
        REGION: 'eu-central-1',
        URL: process.env.REACT_APP_APIGATEWAY
    },
    assetHost: process.env.REACT_APP_ASSET_HOST,
    cognito: {
        IDENTITY_POOL_ID: 'eu-central-1:382927b3-0654-41d4-85eb-4c20c08134d7',
        REGION: 'eu-central-1'
    },
    stage: process.env.REACT_APP_STAGE
};
