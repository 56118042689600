import API from '@aws-amplify/api';
import { inject } from 'mobx-react';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { Alert, Button, Glyphicon } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IServiceRegistrationRequestOauth } from '../../../shared/interfaces/IServiceRegistrationRequest';
import { UserStore } from '../UserStore';
import './spinning.css';

interface IOAuthProps extends RouteComponentProps<{ provider: string }> {
    user: UserStore;
}

interface IOAuthState {
    registered: boolean;
    error: boolean;
}
/**
 * This component is the target of the OAuth redirect.
 * It stores the OAuth authcode for the currently logged-in user and then redirects to the homepage.
 */
@inject(UserStore.INJECT)
export class OAuth extends React.Component<IOAuthProps, IOAuthState> {
    constructor(props: any) {
        super(props);
        this.state = { registered: false, error: false };
    }

    public async componentDidMount() {
        const provider = this.props.match.params.provider;
        const query = queryString.parse(this.props.location.search);
        if (provider && query.code && typeof query.code === 'string') {
            try {
                await this.register(provider, query.code);
                this.setState({ registered: true });
                return;
            } catch (error) {
                this.setState({ error: true });
            }
        }
        this.setState({ error: true });
    }

    public render() {
        if (!this.state.registered) {
            if (!this.state.error) {
                return (
                    <Fragment>
                        Registering Access{' '}
                        <Glyphicon glyph="refresh" className="spinning" />
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <Alert bsStyle="danger">
                            <h4>Login error</h4>
                            <p>An error occurred during registering service.</p>
                            <p>
                                <Button href="/">Back to service list</Button>
                            </p>
                        </Alert>
                    </Fragment>
                );
            }
        }
        return <Redirect to={'/'} />;
    }

    private async register(provider: string, code: string) {
        const request: IServiceRegistrationRequestOauth = {
            type: 'OAUTH',
            provider,
            code
        };
        return API.post('account', `/registerService`, {
            body: request
        });
    }
}
