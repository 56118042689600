import React from 'react';
import { Button, ButtonProps, Glyphicon } from 'react-bootstrap';
import './spinning.css';

interface ILoaderButtonProps extends ButtonProps {
    isLoading: boolean;
    text: string;
    loadingText: string;
    className?: string;
    disabled?: boolean;
}
export const LoaderButton: React.FC<ILoaderButtonProps> = ({
    isLoading,
    text,
    loadingText,
    className = '',
    disabled = false,
    ...props
}) => (
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
        {!isLoading ? text : loadingText}
    </Button>
);
