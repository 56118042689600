import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UnauthenticatedRoute } from './components/UnauthenticatedRoute';
import { Home } from './containers/Home';
import { Login } from './containers/Login';
import { NotFound } from './containers/NotFound';
import { FAQ } from './containers/FAQ';
import { Ssi } from './containers/Ssi';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { OAuth } from './components/OAuth';

export const Routes = () => (
    <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/faq" exact={true} component={FAQ} />
        <UnauthenticatedRoute path="/login" exact={true} component={Login} />
        <UnauthenticatedRoute path="/ssi" exact={true} component={Ssi} />

        <AuthenticatedRoute
            path="/register/:provider"
            component={OAuth}
            exact={true}
        />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
    </Switch>
);
