import { ISsiLoginResponse } from '../../../../shared/interfaces/ssi/ISsiLoginResponse';
import { IIssueCredentialRequest } from '../../../../shared/interfaces/ssi/IIssueCredentialRequest';
import { LoaderButton } from '../LoaderButton'; 
import { Alert, FormControl, FormGroup} from 'react-bootstrap';
import React, { useState } from 'react';
import API from '@aws-amplify/api';


export interface IssueCredentialProps {
    loginResponse: ISsiLoginResponse;
}
export function IssueCredential({loginResponse} : IssueCredentialProps) {

    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [issuedCredentials, setIssuedCredentials] = useState<string[]>([]);
    const [error, setError] = useState<string|null> (null);
    async function handleSubmit (e:any) {
        e.preventDefault();
        setIsLoading(true);

        const issueCredentialRequest: IIssueCredentialRequest = {
            token: loginResponse.token,
            connection_id: loginResponse.connection_id,
            username: loginResponse.username,
            value,
        };
        try {
            const response = await API.post(
                'ssi',
                `/issueCredential`,
                { body: issueCredentialRequest }
            );
            setError(null);
            setIssuedCredentials([...issuedCredentials, value]);
        } catch (e) {
            setError(JSON.stringify(e));
        }
        setIsLoading(false);
    }


    return (
    <div>
        {error !== null &&
                <Alert bsStyle="danger">
                    <h4>Error</h4>
                    <p>An error occurred during issuing the credential.</p>
                </Alert>}
        <form onSubmit={handleSubmit}>
            <h3>Issue a credential</h3>

            <FormGroup controlId="value" bsSize="large">
                <FormControl
                    autoFocus={true}
                    value={value}
                    onChange={(e:any) => setValue(e.target.value)}
                    placeholder="value"
                    disabled={isLoading}
                />
            </FormGroup>
            <LoaderButton
                block={true}
                bsSize="large"
                disabled={value.length === 0}
                type="submit"
                isLoading={isLoading}
                text="Issue Credential"
                loadingText="Issuing credential ..."
            />
        </form>
        <div>
            <p>Issued credentials in this session:</p>
            {issuedCredentials.map(c => <p>{c}</p>)}
        </div>
    </div>
    )
}