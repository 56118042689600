import React from 'react';
import { Col, Grid, ListGroupItem, Row } from 'react-bootstrap';

interface IListGroupItemWithImageProps {
    image: string;
    props: any;
}

export class ListGroupItemWithImage extends React.Component<
    IListGroupItemWithImageProps
> {

    public render() {
        return (
            <ListGroupItem {...this.props.props}>
                <Grid>
                    <Row>
                        <Col xs={4} md={2}>
                            <img src={this.props.image} width="152px"/>
                        </Col>
                        <Col xs={12} md={8}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Grid>
            </ListGroupItem>
        );
    }
}
