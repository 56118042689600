import API from '@aws-amplify/api';
import { inject } from 'mobx-react';
import React, { Fragment } from 'react';
import {
    Alert,
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon
} from 'react-bootstrap';
import { IServiceActivationCredentials } from '../../../shared/interfaces/IServiceActivation';
import { IServiceRegistrationRequestCredentials } from '../../../shared/interfaces/IServiceRegistrationRequest';
import { UserStore } from '../UserStore';
import './spinning.css';

enum CredentialsRegistrationState {
    SUCCESS,
    ERROR,
    IDLE,
    ONGOING
}
interface ICredentialsRegistrationProps {
    service: IServiceActivationCredentials;
    user?: UserStore;
}

interface ICredentialsRegistrationState {
    login: string;
    password: string;
    credentialsRegistration: CredentialsRegistrationState;
}

@inject(UserStore.INJECT)
export class CredentialsRegistration extends React.Component<
    ICredentialsRegistrationProps,
    ICredentialsRegistrationState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            credentialsRegistration: CredentialsRegistrationState.IDLE,
            login: '',
            password: ''
        };
    }

    public render() {
        const alert = (
            <Alert bsStyle="danger">
                <p>
                    An error occurred during registering service. Please check
                    your credentials!
                </p>
            </Alert>
        );

        const formBody = (
            <Fragment>
                <p>
                    In order to activate{' '}
                    <strong>{this.props.service.name}</strong> you need to enter
                    your {this.props.service.name} account credentials here.
                </p>
                {this.state.credentialsRegistration ===
                CredentialsRegistrationState.ERROR
                    ? alert
                    : null}
                <p>
                    <form>
                        <FormGroup controlId="login">
                            <ControlLabel>Login</ControlLabel>
                            <FormControl type="text" onChange={this.setLogin} />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <ControlLabel>Password</ControlLabel>
                            <FormControl
                                type="password"
                                onChange={this.setPassword}
                            />
                        </FormGroup>
                        <Button
                            bsStyle="info"
                            onClick={() =>
                                this.submitCredentials(
                                    this.props.service.provider
                                )
                            }
                        >
                            {this.state.credentialsRegistration ===
                            CredentialsRegistrationState.ONGOING ? (
                                <Glyphicon
                                    glyph="refresh"
                                    className="spinning"
                                />
                            ) : (
                                <Fragment>
                                    Activate {this.props.service.name}
                                </Fragment>
                            )}
                        </Button>
                    </form>
                </p>
            </Fragment>
        );

        const successBody = (
            <Alert bsStyle="info">
                <p>{this.props.service.name} successfully registered!</p>
            </Alert>
        );

        switch (this.state.credentialsRegistration) {
            case CredentialsRegistrationState.ERROR:
            case CredentialsRegistrationState.IDLE:
            case CredentialsRegistrationState.ONGOING:
                return formBody;
            case CredentialsRegistrationState.SUCCESS:
                return successBody;
        }
    }

    private setPassword = (e: any) => {
        this.setState({ password: e.target.value });
    };

    private setLogin = (e: any) => {
        this.setState({ login: e.target.value });
    };

    private submitCredentials = async (provider: string) => {
        this.setState({
            credentialsRegistration: CredentialsRegistrationState.ONGOING
        });
        const request: IServiceRegistrationRequestCredentials = {
            type: 'CREDENTIALS',
            provider,
            login: this.state.login,
            password: this.state.password
        };
        try {
            await API.post('account', `/registerService`, {
                body: request
            });
            this.props.user!.activateService(provider);
            this.setState({
                credentialsRegistration: CredentialsRegistrationState.SUCCESS
            });
        } catch (e) {
            this.setState({
                credentialsRegistration: CredentialsRegistrationState.ERROR
            });
        }
    };
}
