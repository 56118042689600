import { inject } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { Button, Jumbotron } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {ServiceList} from '../components/ServiceList';
import {VehicleList} from '../components/VehicleList';
import { UserStore } from '../UserStore';

interface IHomeProps {
    user?: UserStore;
}

@inject(UserStore.INJECT)
export class Home extends Component<IHomeProps> {
    public render() {
        return (
            <div className="Home">
                {this.props.user!.isAuthenticated
                    ? this.renderContent()
                    : this.renderLander()}
            </div>
        );
    }

    private renderLander() {
        return (
            <Jumbotron>
                <h2>BMW Labs - Registration</h2>
                <p>
                    Thank you for your interest in participating in our pilot
                    project.
                </p>
                <p>
                    <Link to="/login">
                        <Button bsStyle="primary">
                            <strong>
                                Login with BMW Connected Drive account
                            </strong>
                        </Button>
                    </Link>
                    <br />
                </p>
            </Jumbotron>
        );
    }

    private renderContent() {
        return (
            <Fragment>
                <h2>BMW Labs - Registration</h2>
                <ServiceList />
                <VehicleList />
            </Fragment>
        );
    }
}
