import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Application } from './App';
import { config } from './config';
import { UserStore } from './UserStore';

interface IApiEndpointConfig {
    endpoint: string;
    name: string;
    custom_header?: () => Promise<{ [key: string]: string }>;
    service?: string;
    region?: string;
}

const accountEndpoint: IApiEndpointConfig = {
    endpoint: `${config.apiGateway.URL}/account`,
    name: 'account',
    region: config.apiGateway.REGION,
    service: 'execute-api'
};

const ssiEndpoint: IApiEndpointConfig = {
    endpoint: `${config.apiGateway.URL}/ssi`,
    name: 'ssi',
    region: config.apiGateway.REGION,
    service: 'execute-api'
};


if (process.env.REACT_APP_STAGE === 'dev') {
    const localUserId = JSON.stringify({
        services: ['ryd', 'pace-dev'],
    });
    accountEndpoint.custom_header = async () => {
        return { 'cognito-identity-id': localUserId };
    };
}

API.configure({
    endpoints: [accountEndpoint, ssiEndpoint]
});

Auth.configure({
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: false,
    region: config.cognito.REGION
});

const user = new UserStore();

ReactDOM.render(
    <Provider user={user}>
        <Router>
            <Application />
        </Router>
    </Provider>,
    document.getElementById('root')
);
