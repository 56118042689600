import API from '@aws-amplify/api';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    Col,
    Glyphicon,
    Grid,
    ListGroup,
    ListGroupItem,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap';
import { IVehicle } from '../../../shared/interfaces/IVehicle';
import { config } from '../config';
import { UserStore } from '../UserStore';
import Img from 'react-image'
import './spinning.css';

interface IVehicleListProps {
    user?: UserStore;
}

interface IVehicleistState {
    showModal: { [vin: string]: boolean };
}

@inject(UserStore.INJECT)
@observer
export class VehicleList extends Component<
    IVehicleListProps,
    IVehicleistState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: {}
        };
    }

    public async componentDidMount() {
        return this.refreshVehicles();
    }

    public render() {
        const tooltip = (
            <Tooltip id="tooltip">Click here to refresh your vehicles.</Tooltip>
        );
        const reloadButton = (
            <p>
                <OverlayTrigger placement="right" overlay={tooltip}>
                    <Button
                        bsStyle="default"
                        onClick={this.refreshVehiclesFromGcdm}
                    >
                        Reload from BMW Connected Drive
                    </Button>
                </OverlayTrigger>
            </p>
        );
        const vehicles = this.props.user!.vehicles;
        if (!vehicles) {
            return <Glyphicon glyph="refresh" className="spinning" />;
        }
        if (vehicles.length === 0) {
            return (
                <Fragment>
                    {reloadButton}
                    <Alert bsStyle="warning">
                        <p>
                            Unfortunately, you don't have a compatible vehicle
                            linked to your BMW Connected Drive account.
                        </p>
                        <p>
                            <Button
                                bsStyle="warning"
                                href="https://www.bmw-connecteddrive.com/country-region-select/country-region-selection.html"
                            >
                                Click here to link your vehicle to your BMW
                                Connected Drive account.
                            </Button>
                        </p>
                    </Alert>
                </Fragment>
            );
        } else {

            const res = vehicles.map(vehicle => {
                const imagePath = `${config.assetHost}/vehicleImages/${vehicle.vin}.png`;
                const fallbackPath = `${config.assetHost}/vehicleImages/fallback.png`;
                const props = {src: [imagePath, fallbackPath]} as any;
                return (
                    <ListGroupItem
                        header={vehicle.model}
                        key={vehicle.vin}
                    >
                        <Grid>
                            <Row>
                                <Col xs={4} md={2}>
                                    <Img {...props}/>
                                </Col>
                                <Col xs={12} md={8}>
                                </Col>
                            </Row>
                        </Grid>
                    </ListGroupItem>
                );
            });

            return (
                <div className="vehicles">
                    <ListGroup>{res}</ListGroup>
                </div>
            );
        }
    }

    private refreshVehiclesFromGcdm = async () => {
        this.props.user!.vehicles = undefined;
        await API.get('account', '/refreshVehicles', {});
        this.props.user!.vehicles = await this.getVehicles();
    };

    private async refreshVehicles() {
        this.props.user!.vehicles = undefined;
        this.props.user!.vehicles = await this.getVehicles();
    }

    private async getVehicles() {
        const vehicles = (await API.get(
            'account',
            '/vehicles',
            {}
        )) as IVehicle[];
        return vehicles;
    }

    private disableVehicle = (vin: string) => async () => {
        await API.get('account', `/disableVehicle/${vin}`, {});
        return this.refreshVehicles();
    };

    private enableVehicle = (vin: string) => async () => {
        await API.get('account', `/enableVehicle/${vin}`, {});
        await this.refreshVehicles();
    };
}
