import { observable } from 'mobx';
import { IServiceActivation } from '../../shared/interfaces/IServiceActivation';
import { IVehicle } from '../../shared/interfaces/IVehicle';
import API from '@aws-amplify/api';

export class UserStore {
    public static INJECT = 'user';
    @observable
    public isAuthenticated: boolean = false;
    @observable
    public vehicles?: IVehicle[];
    @observable
    public services?: IServiceActivation[];

    private servicesNeedRefresh = true;

    public async refreshServices() {
        if (this.servicesNeedRefresh) {
            this.services = undefined;
            this.services = await this.getServices();
            this.servicesNeedRefresh = false;
        }
    }

    public deactivateService = (provider: string) => async () => {
        await API.get('account', `/unregisterService/${provider}`, {});
        const service = this.getServiceByProvider(provider);
        if (service) {
            service.active = false;
        }
    };

    public activateService(provider: string) {
        const service = this.getServiceByProvider(provider);
        if (service) {
            service.active = true;
        }
        this.servicesNeedRefresh = true;
    }

    private async getServices() {
        const services = (await API.get(
            'account',
            '/services',
            {}
        )) as IServiceActivation[];
        return services;
    }

    private getServiceByProvider(provider: string) {
        if (this.services) {
            return this.services.find(s => s.provider === provider);
        }
    }
}
